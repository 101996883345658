<template>
  <div>

    <b-card class="invoice-preview-card">
      <div class="d-flex justify-content-between flex-wrap">
        <h2 class="my-auto"> {{ $t('customer_information.faq.detail_title') }} <br /></h2>

        <div class="d-flex ">
          <b-button variant="primary" class="d-flex align-items-center mr-1"
            @click="$router.push({ name: 'configs-faqs-edit', params: { id: form.id } })">
            <i class="las la-edit mr-25 la-lg"></i>
            <!-- Nouvelle fiche contact -->
            {{ $t('button.edit') }}
          </b-button>
          <b-button variant="outline-primary" @click="$router.push({ name: 'faqs-list' })"
            class="d-flex align-items-center">
            <i class="las la-arrow-left mr-50"></i>
            <span>{{ $t('button.back_to_list') }}</span>
          </b-button>
        </div>


      </div>
    </b-card>
    <b-card v-if="isCreatingProduct == true">
      <b-col cols="12" class="pt-2 d-flex justify-content-between align-items-center">
        <b-skeleton animation="fade" width="75%"></b-skeleton>
        <b-skeleton animation="fade" width="10%"></b-skeleton>
      </b-col>
      <hr class="my-1">
      <b-col cols="12" class="pt-2 ">
        <b-skeleton animation="fade" width="40%"></b-skeleton>
        <b-skeleton animation="fade" width="50%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
      </b-col>
    </b-card>
    <b-card v-else>
      <b-row class="px-1  d-flex align-items-center">
        <b-col cols="9">
          <h2>
            {{ form.title }}
          </h2>
        </b-col>

        <b-col cols="3" class=" d-flex justify-content-end">
          <h5>
            <b-badge v-if="form.faq_section == null" pill variant="light-primary">
              {{ $t('button_badge_else') }}
            </b-badge>
            <b-badge v-b-tooltip.hover pill variant="light-primary" v-else>
              <!--  {{ reduct(tag.title, 5) }} -->
              {{ form.faq_section.title }}
            </b-badge>
          </h5>
        </b-col>
      </b-row>
      <hr class="py-1" />
      <b-row class="px-3">
        <span v-html="form.response">

        </span>
      </b-row>
    </b-card>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTags,
  BSpinner, BBadge,
  BCardHeader, BSkeleton, BSkeletonImg
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueEditor } from 'vue2-editor'

import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import faqStoreModule from '@/store/faq'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTags,
    VueEditor,
    vSelect,
    BSpinner, BBadge, BCardHeader, BSkeleton, BSkeletonImg
  },
  setup() {
    const requiredStoreModules = [
      { path: 'faqs', module: faqStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    return {
      requiredStoreModules,
    }
  },

  data() {
    return {

      currentId: null,
      form: {
        title: null,
        tags: null,
        response: null,
      },
      isCreatingProduct: false,

    }
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        // await this.loadRoles()
      },
    },

  },

  // beforeDestroy() {
  //   unregisterStoreModule(this.requiredStoreModules)
  // },
  mounted() {
    this.loadFaq()
  },
  methods: {
    ...mapActions('faqs', {
      action_getFaq: 'getFaq',
      action_updateFaq: 'updateFaq',
    }),
    loadFaq() {
      this.isCreatingProduct = true

      this.action_getFaq(this.$route.params.id)
        .then(response => {
          this.isCreatingProduct = false
          this.form = response.data.data
          console.log('this.formload: ', this.form)

        })
        .catch(error => {
          // console.log(error)
          this.isCreatingProduct = false
          // this.errored = true
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Une erreur est survenue',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: '',
            },
          })
        })
    },
    updateFaq() {
      this.isCreatingProduct = true
      this.currentId = this.$route.params.id;
      console.log('this.formupdate: ', this.form)
      this.action_updateFaq({
        id: this.currentId,
        data: this.form,
      })
        .then(response => {
          this.isCreatingProduct = false
          this.isCreatingFiche = false;
          this.currentId = null;
          this.$router.replace({ name: "configs-faqs-list" }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Création réussie',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: '',
              },
            })
          })
        })
        .catch(error => {
          // console.log(error)
          this.isCreatingProduct = false
          // this.errored = true
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Une erreur est survenue',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: '',
            },
          })
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.invoice-preview-wrapper {
  .row.invoice-preview {
    .col-md-8 {
      max-width: 100%;
      flex-grow: 1;
    }

    .invoice-preview-card {
      .card-body:nth-of-type(2) {
        .row {
          >.col-12 {
            max-width: 50% !important;
          }

          .col-12:nth-child(2) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: 0 !important;
          }
        }
      }
    }
  }

  // Action Right Col
  .invoice-actions {
    display: none;
  }
}
</style>